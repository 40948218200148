<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  name:"app",
  mounted(){
    // 检测浏览器是否是Safari
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    // 检测浏览器是否是微信内置的
    const isWechat = String(navigator.userAgent.toLowerCase().match(/MicroMessenger/i)) ==="micromessenger"
    if (isSafari || isWechat ) {
      // 如果是Safari或者微信浏览器，则将所有字体设置为"微软雅黑"
      document.body.style.fontFamily = "'Microsoft YaHei', '微软雅黑'";
    }
  }
}
</script>
<style lang="stylus">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
body
  margin 0px
  padding 0px
  font-family "Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,\\5FAE\8F6F\96C5\9ED1,Arial,sans-serif"
  font-size 14px
  line-height 1.15
  color #303133
  background-color #fff
.el-button--medium
  padding 8px 10px
.el-button--mini
  padding 7px 8px
</style>
