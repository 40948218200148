import * as request from '@/utils/request'

export default {
	weixin_jsconfig(jsurl){
	  return request.ajax('/weixinright/jsconfig',{jsurl:jsurl})
	},
	weixin_jsconfig_agent(jsurl){
	  return request.ajax('/weixinright/jsconfig',{jsurl:jsurl,type:'agentconfig'})
	},
	weixin_qylogin(code){
	  return request.ajax('/weixinright/qyuserlogin',{code:code})
	},
	weixin_external_userinfo(userid){
	  return request.ajax('/weixinright/qy_external_userinfo',{external_userid:userid})
	},
	weixin_followlogdo(data){
	  return request.ajax('/weixinright/followadd',data)
	},
	weixin_followlog(external_userid){
	  return request.post('/weixinright/follow',{external_userid:external_userid})
	},
	weixin_customlog(external_userid){
	  return request.post('/weixinright/customlog',{external_userid:external_userid})
	},	
	weixin_crmorderlist(external_userid){
	  return request.post('/weixinright/crmorderlist',{external_userid:external_userid})
	},	
	weixin_set_external_mobile(external_userid,external_mobile){
	  return request.ajax('/weixinright/set_external_mobile',{external_userid:external_userid,external_mobile:external_mobile})
	},
	weixin_handle_link_order(data){
		//
	  return request.ajax('/weixinright/handle_link_order',data)
	},	
	weixin_handle_unlink_order(data){
		//
	  return request.ajax('/weixinright/handle_unlink_order',data)
	},	
	weixin_dictlist(){
	  return request.post('/weixinright/dictlist')
	},	
	weixin_addservicedo(data){
	  return request.ajax('/weixinright/addservicedo',data)
	},	
	weixin_editservicedo(data){
	  return request.ajax('/weixinright/editservicedo',data)
	},
	weixin_servicelist(external_userid){
	  return request.post('/weixinright/servicelist',{external_userid:external_userid})
	},		
	qyDeviceList(data){
	  return request.post('/weixinright/devicelist',data)
	},
	qyEditDevice(data){
	  return request.post('weixinright/edit_device',data)
	},
	qyUpImg(data){
	  return request.post('weixinright/upload_service_image',data)
	},
	addService(data){
	  return request.post('weixinright/service_add',data)
	},
	editService(data){
	  return request.post('weixinright/service_edit',data)
	},
	qyListing(data){
	  return request.post('weixinright/service_bill_edit',data)
	},
	qy_dict_list(data){
	  return request.post('weixinright/dictlists',data)
	},
	qy_device_unbind(data){
	  return request.post('weixinright/device_unbind',data)
	},
	qy_repeal_service(data){
	  return request.post('weixinright/repeal_service',data)
	},
	qy_remove_service(data){
	  return request.post('weixinright/remove_service',data)
	},
	qy_getLog(data){
		return request.post("weixinright/device_logs",data)
	}
}